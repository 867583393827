
import { Component, Vue } from "vue-property-decorator";
import { apiCountryList, apiSetRule, apiRuleInfo } from "@/api/setting";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: {
    MaterialSelect,
  },
})
export default class ExpressRule extends Vue {
  /** S Data **/
  form: any = {
    express_rule: [
      {
        country: "",
        max_weight: "",
        max_l_width: "",
        max_w_width: "",
        max_h_width: "",
        push_l_w_l_width: "",
        volume: "",
        lists: [
          { first_weight: "", sec_weight: "", freight: "", register: "" },
        ],
      },
    ],
    express_id: "",
  };

  countryList: any = [];
  /** E Data **/

  /** S Methods **/
  handleAddItem() {
    this.form.express_rule.push({
      country: "",
      max_weight: "",
      max_l_width: "",
      max_w_width: "",
      max_h_width: "",
      push_l_w_l_width: "",
      volume: "",
      lists: [{ first_weight: "", sec_weight: "", freight: "", register: "" }],
    });
  }

  handleDeleteItem(index: number) {
    this.form.express_rule.splice(index, 1);
  }

  handleAddItemChild(index: any) {
    this.form.express_rule[index].lists.push({
      first_weight: "",
      sec_weight: "",
      freight: "",
      register: "",
    });
  }

  handleDeleteItemChild(index: any, index1: any) {
    this.form.express_rule[index].lists.splice(index1, 1);
  }

  // 提交表单
  onSubmit(formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (valid) {
        apiSetRule(this.form).then((res) => {
          this.$message.success("保存成功");
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    this.form.express_id = query.id;
    apiRuleInfo({ express_id: query.id }).then((res) => {
      if (res.express_rule.length > 0) {
        this.form = res;
      }
    });
    apiCountryList({}).then((res) => {
      this.countryList = res;
    });
  }

  /** E Life Cycle **/
}
