var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"substance_edit-help"},[_c('div',{staticClass:"ls-card"},[_c('el-page-header',{attrs:{"content":'设置规则'},on:{"back":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：若不限制长宽高或者体积比，请在对应的参数设置为0,长宽高限制公式 若长 +（宽+高）*2≤210cm  应该填入  l+(w+h)*2<=210,若填为0则不启动","type":"info","closable":false,"show-icon":""}})],1),_c('div',{staticClass:"ls-card m-t-16 form-container"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"size":"small","label-width":"120px","label-position":"right"}},_vm._l((_vm.form.express_rule),function(item,index){return _c('div',{key:index,staticClass:"flex",staticStyle:{"flex-direction":"column","justify-content":"flex-start"}},[_c('div',{staticStyle:{"width":"100%","float":"left"}},[_c('el-form-item',{attrs:{"label":"国家","prop":'express_rule.' + index + '.country',"rules":{
              required: true,
              message: '国家不能为空',
              trigger: 'blur',
            }}},[_c('el-select',{staticStyle:{"width":"100px"},attrs:{"filterable":"","clearable":"","placeholder":"请选择"},model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}},_vm._l((_vm.countryList),function(item){return _c('el-option',{key:item.country_code,attrs:{"label":item.cn_country,"value":item.country_code}})}),1)],1)],1),_c('div',{staticClass:"flex",staticStyle:{"margin-left":"40px"}},[_c('el-form-item',{attrs:{"label":"重量小于","prop":'express_rule.' + index + '.max_weight',"rules":{
              required: true,
              message: '最大重量',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入最大重量"},model:{value:(item.max_weight),callback:function ($$v) {_vm.$set(item, "max_weight", $$v)},expression:"item.max_weight"}},[_c('template',{slot:"append"},[_vm._v("g")])],2)],1),_c('el-form-item',{attrs:{"label":"最长小于","prop":'express_rule.' + index + '.max_l_width',"rules":{
              required: true,
              message: '长不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入长"},model:{value:(item.max_l_width),callback:function ($$v) {_vm.$set(item, "max_l_width", $$v)},expression:"item.max_l_width"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('el-form-item',{attrs:{"label":"最宽小于","prop":'express_rule.' + index + '.max_w_width',"rules":{
              required: true,
              message: '宽不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入宽"},model:{value:(item.max_w_width),callback:function ($$v) {_vm.$set(item, "max_w_width", $$v)},expression:"item.max_w_width"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('el-form-item',{attrs:{"label":"最高小于","prop":'express_rule.' + index + '.max_h_width',"rules":{
              required: true,
              message: '高不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入高"},model:{value:(item.max_h_width),callback:function ($$v) {_vm.$set(item, "max_h_width", $$v)},expression:"item.max_h_width"}},[_c('template',{slot:"append"},[_vm._v("cm")])],2)],1),_c('el-form-item',{attrs:{"label":"长宽高限制公式","prop":'express_rule.' + index + '.push_l_w_l_width',"rules":{
              required: true,
              message: '长宽高限制公式不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",staticStyle:{"width":"160px"},attrs:{"placeholder":"请输入长宽高限制公式"},model:{value:(item.push_l_w_l_width),callback:function ($$v) {_vm.$set(item, "push_l_w_l_width", $$v)},expression:"item.push_l_w_l_width"}})],1),_c('el-form-item',{attrs:{"label":"体积比","prop":'express_rule.' + index + '.volume',"rules":{
              required: true,
              message: '体积比不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",staticStyle:{"width":"70px"},attrs:{"placeholder":"请输入体积比"},model:{value:(item.volume),callback:function ($$v) {_vm.$set(item, "volume", $$v)},expression:"item.volume"}})],1),_c('el-form-item',{staticStyle:{"margin-left":"-50px"}},[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.handleAddItem}},[_vm._v("添加")]),(index != 0)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleDeleteItem(index)}}},[_vm._v("刪除")]):_vm._e()],1)],1),_vm._l((item.lists),function(item1,index1){return _c('div',{key:index1,staticClass:"flex",staticStyle:{"width":"100%","float":"left","margin-left":"100px"}},[_c('el-form-item',{attrs:{"label":"首重区间","prop":'express_rule.' + index + '.lists.' + index1 + '.first_weight',"rules":{
              required: true,
              message: '首重区间不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入体积比"},model:{value:(item1.first_weight),callback:function ($$v) {_vm.$set(item1, "first_weight", $$v)},expression:"item1.first_weight"}})],1),_c('el-form-item',{attrs:{"label":"续重区间","prop":'express_rule.' + index + '.lists.' + index1 + '.sec_weight',"rules":{
              required: true,
              message: '续重区间不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入续重区间"},model:{value:(item1.sec_weight),callback:function ($$v) {_vm.$set(item1, "sec_weight", $$v)},expression:"item1.sec_weight"}})],1),_c('el-form-item',{attrs:{"label":"运费","prop":'express_rule.' + index + '.lists.' + index1 + '.freight',"rules":{
              required: true,
              message: '运费不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入运费"},model:{value:(item1.freight),callback:function ($$v) {_vm.$set(item1, "freight", $$v)},expression:"item1.freight"}})],1),_c('el-form-item',{attrs:{"label":"挂号费","prop":'express_rule.' + index + '.lists.' + index1 + '.register',"rules":{
              required: true,
              message: '挂号费不能为空',
              trigger: 'blur',
            }}},[_c('el-input',{staticClass:"rule-input",attrs:{"placeholder":"请输入挂号费"},model:{value:(item1.register),callback:function ($$v) {_vm.$set(item1, "register", $$v)},expression:"item1.register"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleAddItemChild(index)}}},[_vm._v("添加")]),(index1 != 0)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleDeleteItemChild(index, index1)}}},[_vm._v("刪除")]):_vm._e()],1)],1)})],2)}),0)],1),_c('div',{staticClass:"bg-white ls-fixed-footer"},[_c('div',{staticClass:"row-center flex",staticStyle:{"height":"100%"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.onSubmit('form')}}},[_vm._v("保存")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }